import { get, set, unset, has, isEqual, remove } from 'lodash';
import {
  EXPERIMENT_POST_PAGE_MIGRATION,
  EXPERIMENT_OFFLINE_POST_PAGE_MIGRATION,
} from '@wix/communities-blog-experiments';
import guidUtils from '@wix/santa-core-utils/dist/cjs/coreUtils/core/guidUtils';
import page from './page';
import experiments from './experiments';
import translation from './translation';
import monitoring from './monitoring';
import { blogAppDefId } from '../constants/apps';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import { AUTOPILOT_USER_UUID, ADI_QA_UUIDS } from '../constants/users';
import { TPA_PAGE_ID_POST, TPA_PAGE_ID_BLOG } from '../constants/tpa-pages';
import { BASE_API_URL } from '../constants/api';
import { getCommonSettings, setCommonSettings, patchSettings, fetchSettings } from './settings';
import { getSiteMemberId } from './instance';
import retry, { repeat } from './retry';
import actions from './actions';
import bi from './bi';

const { translate } = translation;

const addPostPage = async (sdk, appToken) => {
  let postPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST });
  if (!postPage) {
    const primaryPageRef = await sdk.document.pages.getPrimary(appToken);
    await sdk.tpa.add.component(appToken, {
      componentType: 'PAGE',
      appDefinitionId: blogAppDefId,
      page: { pageId: TPA_PAGE_ID_POST },
    });
    await sdk.document.pages.navigateTo(appToken, { pageRef: primaryPageRef });
    postPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST });
  }
  return postPage;
};

const patchStyles = (styles, fonts) => {
  const copyParamValue = (fromPath, toPath) => {
    const value = get(styles, fromPath);
    value && set(styles, toPath, value);
  };
  const replaceParamValue = (fromPath, withPath) => {
    copyParamValue(fromPath, withPath);
    unset(styles, fromPath);
  };
  const removeParam = path => {
    unset(styles, path);
  };
  const modifyFontSize = (path, size) => {
    let font;
    try {
      font = JSON.parse(get(styles, path));
    } catch (_) {
      return;
    }
    const editorFont = get(fonts, `${font.editorKey}`);
    if (font.preset !== 'Custom' && editorFont) {
      font.family = editorFont.family;
      font.preset = 'Custom';
      font.style = {
        bold: Boolean(editorFont.bold),
        italic: Boolean(editorFont.italic),
        underline: Boolean(font.style.underline),
      };
    }
    font.size = size;
    set(styles, path, JSON.stringify(font));
  };

  replaceParamValue('param_font_post-descriptionFont', 'param_font_post-pageFont');
  copyParamValue('param_color_post-textColor', 'param_color_post-titleColor');
  copyParamValue('alpha-param_color_post-textColor', 'alpha-param_color_post-titleColor');
  copyParamValue('param_color_blog-linkHashtagColor', 'param_color_navigation-textColorActive');
  copyParamValue('alpha-param_color_blog-linkHashtagColor', 'alpha-param_color_navigation-textColorActive');
  modifyFontSize('param_font_post-pageFont', 18);
  modifyFontSize('param_font_post-titleFont', 40);
  removeParam('param_boolean_blog-isPostListFullWidthEnabled');
};

const patchAppSettings = async (sdk, appToken, appSettings) => {
  const editorFonts = await sdk.document.fonts.getFontsOptions(appToken);

  const getCssFontFamily = (family, fontsOptions) => {
    const matchingFont = fontsOptions
      .reduce((result, { fonts }) => result.concat(fonts), [])
      .find(font => font.fontFamily === family);
    return get(matchingFont, 'cssFontFamily');
  };

  const parseCssFontFamily = cssValue => {
    const match = cssValue.match(/^font: ?(?:[^ ]* ){4}([^;]+)/);
    return match && match[1];
  };

  const copyParamValue = (fromPath, toPath) => {
    const value = get(appSettings, fromPath);
    value && set(appSettings, toPath, value);
  };
  const replaceParamValue = (fromPath, withPath) => {
    copyParamValue(fromPath, withPath);
    unset(appSettings, fromPath);
  };
  const removeParam = path => {
    unset(appSettings, path);
  };
  const modifyFontSize = (path, size) => {
    if (!has(appSettings, path)) {
      return;
    }

    const cssFontFamily =
      getCssFontFamily(get(appSettings, `${path}.family`), editorFonts) ||
      parseCssFontFamily(get(appSettings, `${path}.value`, '')) ||
      '"open sans", sans-serif';

    set(appSettings, `${path}.size`, size);
    set(appSettings, `${path}.preset`, 'Custom');

    const style = get(appSettings, `${path}.style`, {});
    const value = `font:${style.italic ? 'italic' : 'normal'} normal ${
      style.bold ? 'bold' : 'normal'
    } ${size}px/1.4em ${cssFontFamily};${style.underline ? 'text-decoration:underline;' : ''}`;

    set(appSettings, `${path}.value`, value);
  };

  replaceParamValue('style.fonts.post-descriptionFont', 'style.fonts.post-pageFont');
  copyParamValue('style.colors.post-textColor', 'style.colors.post-titleColor');
  copyParamValue('style.colors.blog-linkHashtagColor', 'style.colors.navigation-textColorActive');
  modifyFontSize('style.fonts.post-pageFont', 18);
  modifyFontSize('style.fonts.post-titleFont', 40);
  removeParam('style.booleans.blog-isPostListFullWidthEnabled');
};

const updateAppSettings = async (appInstance, componentId, appSettings) => {
  await patchSettings(appInstance, componentId, 'draft', appSettings);
  await patchSettings(appInstance, componentId, 'published', appSettings);
};

const copyBlogStyles = async (sdk, appToken) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);

  const blogComponent = blogAppComponents.find(component => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return false;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, { id: blogComponent.id });
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const postComponent = blogAppComponents.find(component => component.widgetId === POST_WIDGET_ID);
  if (!postComponent) {
    return false;
  }
  const postComponentRef = await sdk.document.components.getById(appToken, { id: postComponent.id });

  const postComponentStyle = { ...blogComponentStyle.style.properties };
  const fonts = await sdk.document.theme.fonts.getMap();
  patchStyles(postComponentStyle, fonts);
  await sdk.components.style.update(appToken, {
    componentRef: postComponentRef,
    style: postComponentStyle,
  });

  const appInstance = await sdk.document.info.getAppInstance(appToken);
  const blogAppSettings = await fetchSettings(appInstance, blogComponent.id, 'draft');
  const postAppSettings = { style: { ...get(blogAppSettings, 'style', {}) } };
  await patchAppSettings(sdk, appToken, postAppSettings);
  await updateAppSettings(appInstance, postComponent.id, postAppSettings);

  return true;
};

const showMigrationNotification = (sdk, appToken, postPage) => {
  sdk.editor
    .showNotification(appToken, {
      message: translate('post-page-migration.post-page-added-notification'),
      type: 'info',
      link: { caption: translate('post-page-migration.learn-more-link') },
    })
    .then(
      linkWasClicked => linkWasClicked && sdk.document.pages.navigateTo(appToken, { pageRef: { id: postPage.id } }),
    );
};

const prepare = async ({ sdk, appToken }) => {
  const isExperimentPostPageMigrationEnabled = experiments.isEnabled(EXPERIMENT_POST_PAGE_MIGRATION);
  if (!isExperimentPostPageMigrationEnabled) {
    return;
  }

  const userId = await getSiteMemberId(sdk, appToken);
  if (userId === AUTOPILOT_USER_UUID) {
    return;
  }

  await monitoring.toMonitored(
    'post-page-migration.prepare',
    (async () => {
      const appInstance = await sdk.document.info.getAppInstance(appToken);
      const postPageEnabled = await page.isPostPageEnabled(appInstance);
      if (postPageEnabled) {
        return;
      }

      const postPage = await addPostPage(sdk, appToken);
      if (!postPage) {
        return;
      }

      const commonSettings = await getCommonSettings(appInstance, 'draft');
      if (get(commonSettings, 'postPageEnabled')) {
        return;
      }

      const stylesWereCopied = await copyBlogStyles(sdk, appToken);
      if (!stylesWereCopied) {
        return;
      }

      await sdk.document.save(appToken);
      await setCommonSettings(appInstance, 'draft', { postPageEnabled: true });
      await sdk.document.tpa.app.refreshApp();
      showMigrationNotification(sdk, appToken, postPage);
    })(),
    false,
  );
};

const getApiUrl = instance => `${BASE_API_URL}/_api/post-page-migration/start?viewMode=editor&instance=${instance}`;

const triggerPostPageMigrationInBackend = instance =>
  fetch(getApiUrl(instance), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  }).then(res => {
    if (!res.ok) {
      throw Error(res.statusText);
    }
  });

let migrationStarted = false;
const start = async ({ sdk, appToken }) => {
  const isExperimentPostPageMigrationEnabled = experiments.isEnabled(EXPERIMENT_POST_PAGE_MIGRATION);
  if (!isExperimentPostPageMigrationEnabled) {
    return;
  }

  const userId = await getSiteMemberId(sdk, appToken);
  if (userId === AUTOPILOT_USER_UUID) {
    return;
  }

  if (migrationStarted) {
    return;
  }
  migrationStarted = true;

  await monitoring.toMonitored(
    'post-page-migration.start',
    (async () => {
      const appInstance = await sdk.document.info.getAppInstance(appToken);
      const postPageEnabled = await page.isPostPageEnabled(appInstance);
      if (postPageEnabled) {
        return;
      }

      const commonSettings = await getCommonSettings(appInstance, 'draft');
      if (!get(commonSettings, 'postPageEnabled')) {
        return;
      }
      await triggerPostPageMigrationInBackend(appInstance);

      (await repeat(
        async stop => {
          const enabled = await page.isPostPageEnabled(appInstance);
          if (enabled) {
            stop();
          }
          return enabled;
        },
        5,
        3000,
      )) && (await sdk.document.tpa.app.refreshApp());
    })(),
    false,
  );

  migrationStarted = false;
};

const copyBlogAppSettings = async (sdk, appToken, blogComponentId, postComponentId, externalId) => {
  const appInstance = await sdk.document.info.getAppInstance(appToken);
  const blogAppSettings = await fetchSettings(appInstance, blogComponentId, externalId);
  const postAppSettings = { style: get(blogAppSettings, 'style', {}) };
  await patchAppSettings(sdk, appToken, postAppSettings);
  await patchSettings(appInstance, postComponentId, externalId, postAppSettings);

  const updatedPostAppSettings = await fetchSettings(appInstance, postComponentId, externalId);
  const isSettingsSuccessfullyCopied = isEqual(postAppSettings.style, get(updatedPostAppSettings, 'style', {}));
  if (!isSettingsSuccessfullyCopied) {
    return false;
  }
  return true;
};

const resolveComponentRefs = async (sdk, appToken) => {
  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);

  const blogComponent = blogAppComponents.find(component => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return [];
  }

  const postComponent = blogAppComponents.find(component => component.widgetId === POST_WIDGET_ID);
  if (!postComponent) {
    return [];
  }

  return Promise.all([
    sdk.document.components.getById(appToken, { id: blogComponent.id }),
    sdk.document.components.getById(appToken, { id: postComponent.id }),
  ]);
};

const copyDraftAndPublishedStyles = async (sdk, appToken, blogComponentRef, postComponentRef) => {
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const isStylesEmpty = get(blogComponentStyle, 'type') !== 'ComponentStyle';
  if (isStylesEmpty) {
    return true;
  }

  const postComponentStyle = { ...blogComponentStyle.style.properties };
  const fonts = await sdk.document.theme.fonts.getMap();
  patchStyles(postComponentStyle, fonts);
  await sdk.components.style.update(appToken, {
    componentRef: postComponentRef,
    style: postComponentStyle,
  });

  const updatedPostComponentStyle = await sdk.components.style.get(appToken, { componentRef: postComponentRef });
  const isComponentStyleCopied = isEqual(postComponentStyle, get(updatedPostComponentStyle, 'style.properties', {}));
  if (!isComponentStyleCopied) {
    return false;
  }

  if (!(await copyBlogAppSettings(sdk, appToken, blogComponentRef.id, postComponentRef.id, 'draft'))) {
    return false;
  }

  if (!(await copyBlogAppSettings(sdk, appToken, blogComponentRef.id, postComponentRef.id, 'published'))) {
    return false;
  }

  return true;
};

const getUniquePathnameForPostPage = async (sdk, appToken) => {
  const pagesData = await sdk.pages.data.getAll(appToken);
  if (!pagesData.find(data => data.pageUriSEO === 'post')) {
    return 'post';
  }

  const filteredData = pagesData.filter(data => /^post-\d+$/.test(data.pageUriSEO));
  if (filteredData.length === 0) {
    return 'post-1';
  }
  const usedNumbers = filteredData.map(data => parseInt(data.pageUriSEO.match(/^post-(\d+)$/)[1], 10));
  const lastUsedNumber = usedNumbers.sort((a, b) => a - b).pop();

  return `post-${lastUsedNumber + 1}`;
};

const getUniqueSectionIdForPostPage = async (sdk, appToken) => {
  for (let i = 0; i < 10; i++) {
    const sectionId = `TPAMultiSection_${guidUtils.getUniqueId()}`;
    const isSectionIdTaken = Boolean(await sdk.document.components.getById(appToken, { id: sectionId }));
    if (!isSectionIdTaken) {
      return sectionId;
    }
  }

  throw new Error('cannot generate an unique section id');
};

const buildAndAddPostPage = async (sdk, appToken, blogPageId) => {
  const pageDefinition = await sdk.document.pages.serialize(appToken, {
    pageRef: { id: blogPageId },
    maintainIdentifiers: false,
  });
  if (!pageDefinition) {
    throw new Error('Unable to serialize a feed page');
  }
  const pageDefinitionData = pageDefinition.data;

  pageDefinition.data = {
    descriptionSEO: '',
    hidePage: true,
    hideTitle: true,
    icon: '',
    indexable: true,
    isLandingPage: pageDefinitionData.isLandingPage,
    isMobileLandingPage: pageDefinitionData.isMobileLandingPage,
    isPopup: false,
    managingAppDefId: blogAppDefId,
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false, pageId: 'masterPage' },
    metaKeywordsSEO: '',
    pageBackgrounds: pageDefinitionData.pageBackgrounds,
    pageSecurity: pageDefinitionData.pageSecurity,
    pageTitleSEO: '',
    pageUriSEO: await getUniquePathnameForPostPage(sdk, appToken),
    title: 'Post',
    tpaApplicationId: pageDefinitionData.tpaApplicationId,
    tpaPageId: TPA_PAGE_ID_POST,
    translationData: { uriSEOTranslated: false },
    type: 'Page',
    underConstruction: false,
  };

  const isProGallery = component => get(component, 'data.widgetId') === '142bb34d-3439-576a-7118-683e690a1e0d';
  remove(pageDefinition.components, isProGallery);
  remove(pageDefinition.mobileComponents, isProGallery);

  const isBlogComponent = component => get(component, 'data.widgetId') === BLOG_WIDGET_ID;
  const blogComponentIndex = pageDefinition.components.findIndex(isBlogComponent);
  const [blogComponentDefinition] = remove(pageDefinition.components, isBlogComponent);
  if (!blogComponentDefinition) {
    throw new Error('Unable to find a feed component');
  }

  await sdk.document.pages.navigateTo(appToken, { pageRef: { id: blogPageId } });

  const postPagePageRef = await sdk.pages.add(appToken, {
    title: pageDefinition.data.title,
    definition: pageDefinition,
    shouldAddMenuItem: false,
  });

  if (!postPagePageRef) {
    throw new Error('failed to install the post page');
  }

  const postComponentDefinition = {
    componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: blogComponentDefinition.layout,
    data: {
      type: 'TPAMultiSection',
      applicationId: `${pageDefinitionData.tpaApplicationId}`,
      appDefinitionId: blogAppDefId,
      widgetId: POST_WIDGET_ID,
      referenceId: '',
    },
    style: 'tpas0',
  };
  const mobileLayout = get(blogComponentDefinition, 'mobileStructure.layout');
  mobileLayout && set(postComponentDefinition, 'mobileStructure.layout', mobileLayout);

  const sectionId = await getUniqueSectionIdForPostPage(sdk, appToken);

  const postComponentRef = await sdk.components.add(appToken, {
    componentDefinition: postComponentDefinition,
    pageRef: postPagePageRef,
    optionalIndex: blogComponentIndex,
    customId: sectionId,
  });

  if (!postComponentRef) {
    throw new Error('failed to add the post component');
  }

  const yCoordinate = get(blogComponentDefinition, 'layout.y');
  yCoordinate &&
    (await sdk.components.layout.update(appToken, {
      componentRef: postComponentRef,
      layout: { y: yCoordinate },
    }));

  if (pageDefinitionData.pageSecurity.requireLogin) {
    await sdk.document.pages.permissions.duplicateGroupsPermissions(appToken, {
      sourcePageRef: { id: blogPageId },
      destinationPageRef: postPagePageRef,
    });
  }

  return postPagePageRef;
};

// https://bo.wix.com/wix-docs/client/client-frameworks#offline-migration
//
// To trigger the migration, the service logs in to a autopilot user and opens the editor using puppeteer.
// The editor will be open twice - one time with the last saved revision and
// one time with the last published revision (or once if they are the same).
//
// Two revisions will be modified: 1. Last saved revision 2. Last published revision
const migrateOffline = async ({ sdk, appToken }) => {
  const migrationEnabled = experiments.isEnabled(EXPERIMENT_OFFLINE_POST_PAGE_MIGRATION);
  if (!migrationEnabled) {
    return;
  }

  await monitoring.toMonitored(
    'post-page-migration.migrate-offline',
    (async () => {
      const [siteMemberId, userId] = await Promise.all([
        getSiteMemberId(sdk, appToken),
        sdk.document.info.getUserId(appToken),
      ]);
      if (!(siteMemberId === AUTOPILOT_USER_UUID || ADI_QA_UUIDS.includes(userId))) {
        throw Error('insufficient permissions');
      }

      const appInstance = await sdk.document.info.getAppInstance(appToken);
      const postPageEnabled = await page.isPostPageEnabled(appInstance);
      if (postPageEnabled) {
        throw Error('postPageEnabled flag is set');
      }

      // Published revision might not have blog page.
      // Thus migration should be skipped for this revision.
      const blogPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_BLOG });
      if (!blogPage) {
        return;
      }

      const postPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST });
      if (postPage) {
        await sdk.document.pages.remove(appToken, { pageRef: { id: postPage.id }, shouldShowEditorRemovePanel: false });
        if (await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST })) {
          throw Error('failed to remove the post page');
        }
      }

      const addedPageRef = await buildAndAddPostPage(sdk, appToken, blogPage.id);

      let blogComponentRef, postComponentRef;
      await retry(async () => {
        [blogComponentRef, postComponentRef] = await resolveComponentRefs(sdk, appToken);
        if (!blogComponentRef || !postComponentRef) {
          throw Error('failed to resolve components');
        }
      });

      const stylesWereCopied = await copyDraftAndPublishedStyles(sdk, appToken, blogComponentRef, postComponentRef);
      if (!stylesWereCopied) {
        throw Error('failed to copy settings');
      }

      const hasPassword = await sdk.document.pages.permissions.hasPassword(appToken, { pageRef: { id: blogPage.id } });
      if (hasPassword) {
        await sdk.document.pages.permissions.duplicatePagePassword(appToken, {
          destinationPageRef: addedPageRef,
          sourcePageRef: { id: blogPage.id },
        });
      }
    })(),
  );
};

const logInstalledApp = async ({ sdk, appToken }, appDefinitionId) => {
  const migrationEnabled = experiments.isEnabled(EXPERIMENT_OFFLINE_POST_PAGE_MIGRATION);
  if (!migrationEnabled) {
    return;
  }

  const userId = await getSiteMemberId(sdk, appToken);
  if (userId !== AUTOPILOT_USER_UUID) {
    return;
  }

  await actions.initBiService({ sdk, appToken });
  await bi.log({ evid: 402, app_id: appDefinitionId, uuid: userId });
};

export default { prepare, start, migrateOffline, getApiUrl, logInstalledApp };
